import { Cubit } from "blac";
import Path from "src/constants/path";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { UserPreferenceKeys } from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { userPreferences } from "src/state/state";
import type { ChatBloc, ChatMessage } from "src/ui/components/Chat/ChatBloc";

import NavIconHome from "src/ui/assets/icons/nav-home.svg";
import NavIconHomeActive from "src/ui/assets/icons/nav-home-active.svg";
import NavIconChat from "src/ui/assets/icons/nav-chat.svg";
import NavIconChatActive from "src/ui/assets/icons/nav-chat-active.svg";
import NavIconLabs from "src/ui/assets/icons/nav-labs.svg";
import NavIconLabsActive from "src/ui/assets/icons/nav-labs-active.svg";
import NavIconPlan from "src/ui/assets/icons/nav-plan.svg";
import NavIconPlanActive from "src/ui/assets/icons/nav-plan-active.svg";
import NavIconProfile from "src/ui/assets/icons/nav-profile.svg";
import NavIconProfileActive from "src/ui/assets/icons/nav-profile-active.svg";
import type { TranslationKey } from "src/types/translationKey";

interface AppViewData {
  label: TranslationKey;
  title?: string;
  path: Path;
  icon?: {
    active: string;
    normal: string;
  };
}

export const navItems: AppViewData[] = [
  {
    label: "nav_home",
    path: Path.home,
    icon: {
      active: NavIconHomeActive,
      normal: NavIconHome
    }
  },
  {
    label: "nav_messages",
    path: Path.chat,
    icon: {
      active: NavIconChatActive,
      normal: NavIconChat
    }
  },
  {
    label: "nav_my_plan",
    path: Path.myPlan,
    icon: {
      active: NavIconPlanActive,
      normal: NavIconPlan
    }
  },
  {
    label: "nav_lab_results",
    path: Path.labResults,
    icon: {
      active: NavIconLabsActive,
      normal: NavIconLabs
    }
  },
  {
    label: "nav_profile",
    path: Path.profile,
    icon: {
      active: NavIconProfileActive,
      normal: NavIconProfile
    }
  }
];

export enum KnownPartnerSources {
  transcarent = "transcarent"
}

export interface AppViewState {
  view: AppViewData;
  showTabBar?: boolean;
  showBackButton?: boolean;
  showLogo?: boolean;
  showTitle?: string;
  hasAccess?: boolean;
  forceSkeletons?: boolean;
  messagesLength?: number;
  lastMessageIsFromUser?: boolean;
  showForceMedicalQuestionnaire?: boolean;
  partnerSource?: KnownPartnerSources;
  inApp: boolean;
  partnerSession?: boolean;
}

export default class AppViewCubit extends Cubit<AppViewState> {
  private backButtonInstances = 0;
  private showLogoInstances = 0;
  private titleList: string[] = [];
  overlayIds = new Set<string>();
  chatBloc?: ChatBloc;

  constructor() {
    super({
      inApp: false,
      view: {
        label: "nav_home",
        path: Path.app
      },
      showTabBar: true,
      forceSkeletons: false
    });

    this.setPartnerSession();

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window.Set as any).forceSkeletons = this.setForceSkeletons;
  }

  setPartnerSource(source: KnownPartnerSources | string) {
    const match = Object.values(KnownPartnerSources).find((s) =>
      source.toLowerCase().includes(s.toLowerCase())
    );

    if (match && match !== this.state.partnerSource) {
      this.emit({ ...this.state, partnerSource: match });
    }
  }

  setInApp = (inApp: boolean): void => {
    this.emit({ ...this.state, inApp });
  };

  setPartnerSession = (debugPartnerSession?: boolean): void => {
    const isTc = navigator.userAgent.includes("TCMobileInAppBrowser");
    if (isTc || debugPartnerSession) {
      this.emit({ ...this.state, partnerSession: true });
    } else {
      this.emit({ ...this.state, partnerSession: false });
    }
  };

  public readonly setForceSkeletons = (set: boolean): void => {
    this.emit({ ...this.state, forceSkeletons: set });
  };

  public readonly setOnboardingDone = (): void => {
    if (userPreferences.state[UserPreferenceKeys.onboardingDone]) return;

    void userPreferences.updateUserPreferences({
      [UserPreferenceKeys.onboardingDone]: "true"
    });
    addSentryBreadcrumb("appView", `Set onboarding done`);
  };

  public readonly setPageByPath = (path: Path): void => {
    const newPage = navItems.find((item) => path.startsWith(item.path));
    if (newPage) {
      this.emit({ ...this.state, view: newPage });
    }
  };

  public readonly setTabBarVisible = (setTo: boolean): void => {
    this.emit({ ...this.state, showTabBar: setTo });
  };

  public readonly toggleTitle = (setTo: boolean, title: string): void => {
    if (setTo) {
      this.titleList = [...this.titleList, title];
    } else {
      this.titleList = this.titleList = this.titleList.filter(
        (t) => t !== title
      );
    }

    const showTitle = this.titleList[this.titleList.length - 1];
    this.emit({ ...this.state, showTitle });
  };

  public readonly updateBackButton = (added: boolean): void => {
    this.backButtonInstances += added ? 1 : -1;
    const show = this.backButtonInstances !== 0;
    if (show !== this.state.showBackButton) {
      this.emit({ ...this.state, showBackButton: show });
    }
  };

  public readonly updateShowLogo = (added: boolean): void => {
    this.showLogoInstances += added ? 1 : -1;
    const show = this.showLogoInstances !== 0;
    if (show !== this.state.showLogo) {
      this.emit({ ...this.state, showLogo: show });
    }
  };

  jsonToState(state: string): AppViewState {
    const parsed = super.jsonToState(state);
    parsed.showTitle = undefined;
    parsed.showLogo = undefined;
    parsed.showBackButton = undefined;
    return parsed;
  }

  setMessagesState(messages: ChatMessage[]): void {
    const messagesLength = messages.length;
    const lastMessage = messages[messagesLength - 1];
    const lastMessageIsFromUser = lastMessage.senderType === "USER";

    this.emit({
      ...this.state,
      messagesLength,
      lastMessageIsFromUser
    });
  }
}
