import styled from "@emotion/styled";
import React, { FC, useEffect } from "react";
import { PressEvent } from "react-aria";
import { Button as AriaButton, ButtonProps } from "react-aria-components";

const ButtonStyled = styled(AriaButton)`
  display: block;
  margin: 0;
  padding: 0;
  border-radius: 7.5rem;
  background: var(--btn-background, #fff);
  color: var(--btn-color, var(--greys-charcoal, #212121));
  border: 0;
  outline: 0;
  cursor: pointer;
  transition:
    box-shadow 0.2s ease,
    opacity 0.2s ease,
    color 0.2s ease,
    filter 0.2s ease;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  min-width: max-content;
  line-height: inherit;
  --border-size: 0px;
  --btn-padding: 1rem 1.375rem 0.9375rem 1.75rem;
  --btn-gap: 0.375rem;

  &[data-center="true"] {
    margin: 0 auto;
  }

  &[data-hide-arrow="true"] {
    --btn-gap: 0;
    --btn-padding: 1rem 1.375rem 0.9375rem 1.375rem;
  }

  &[data-theme="sunrise"] {
    --btn-background: var(
      --sunrise-horizontal-regular,
      linear-gradient(90deg, #80aeff 0.36%, #f7bde6 52.67%, #ffbd70 99.65%)
    );
    --inner-background: #fff;
    --inner-opacity: 0.3;

    &[data-outline="false"] {
      --focus-inner-opacity: 0;
    }

    &[data-outline="initial"] {
      --inner-opacity: 1;
      --focus-inner-opacity: 0.5;
      --border-size: 1px;
    }
  }

  &[data-theme="charcoal"] {
    --inner-background: var(--greys-charcoal, #212121);
    --btn-background: var(
      --sunrise-horizontal-dusk,
      linear-gradient(90deg, #0e3763 0%, #5b4855 52.6%, #8a6947 100%)
    );
    --btn-color: #fff;
    --inner-opacity: 1;

    &[data-outline="false"] {
      --focus-inner-opacity: 0;
    }
  }

  &[data-theme="blur-charcoal"] {
    --btn-background: var(--white-26, rgba(255, 255, 255, 0.26));
    --inner-background: var(
      --sunrise-horizontal-dusk,
      linear-gradient(90deg, #0e3763 0%, #5b4855 52.6%, #8a6947 100%)
    );
    --btn-color: var(--greys-charcoal, #212121);
    --inner-opacity: 0;
    --focus-inner-opacity: 1;
    --focus-btn-color: #fff;

    &[data-active="true"] {
      --inner-opacity: 1;
      --btn-color: #fff;
    }
  }

  &[data-theme="transparent"] {
    --inner-background: transparent;
    --btn-background: transparent;
  }

  &[data-theme="rxdiet"] {
    --btn-background: var(--Partners-RxDiet-Primary, #6235cc);
    --btn-color: #fff;
    --focus-btn-color: #fff;
    --inner-background: #fff;
    --inner-opacity: 0;
    --focus-inner-opacity: 0.2;
  }

  &[data-outline="true"][data-theme="sunrise"] {
    --inner-background: #fff;
    --inner-opacity: 1;
    --border-size: 1px;
  }

  &[data-outline="true"][data-theme="charcoal"] {
    --inner-background: #fff;
    --inner-opacity: 1;
    --border-size: 1px;
    --btn-color: var(--greys-charcoal, #212121);
  }

  &[data-size="medium"] {
    --btn-padding: 0.8125rem 0.75rem 0.75rem 1rem;

    &[data-hide-arrow="true"] {
      --btn-padding: 0.8125rem 1rem 0.75rem 1rem;
    }
  }

  &[data-size="small"] {
    --btn-padding: 0.3rem 0.65rem 0.3rem 1rem;

    &[data-hide-arrow="true"] {
      --btn-padding: 0.3rem 1rem 0.3rem;
    }
  }

  &:hover {
    &:not([data-disabled="true"], [data-theme="rxdiet"]) {
      box-shadow:
        0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        -3px 3px 9px 0px rgba(0, 0, 0, 0.2),
        -13px 11px 17px 0px rgba(0, 0, 0, 0.17),
        -28px 24px 22px 0px rgba(0, 0, 0, 0.1),
        -50px 43px 26px 0px rgba(0, 0, 0, 0.03),
        -79px 67px 29px 0px rgba(0, 0, 0, 0);
    }

    --btn-color: var(--focus-btn-color);
  }

  &[data-disabled="true"] {
    opacity: 0.7;
    filter: grayscale(1);
    cursor: not-allowed;
  }
`;

const ButtonInner = styled.div`
  display: flex;
  padding: var(--btn-padding);
  justify-content: center;
  align-items: center;
  position: relative;
  gap: var(--btn-gap);
  padding: var(--btn-padding);

  &::before {
    transition:
      opacity 0.2s ease,
      background-color 0.2s ease;
    background: var(--inner-background, transparent);
    content: "";
    display: block;
    position: absolute;
    inset: var(--border-size);
    border-radius: calc(7.5rem - var(--border-size));
    opacity: var(--inner-opacity, 1);

    button:not([data-disabled="true"]):hover & {
      opacity: var(--focus-inner-opacity, 1);
    }
  }
`;

const ButtonChildren = styled.div`
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  position: relative;

  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: -0.02rem;
  text-transform: capitalize;

  button[data-action="true"] & {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.0175rem;
    padding-left: 0.3rem;
  }

  button[data-size="small"] & {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.02em;
  }
`;

const ButtonIcon = styled.div`
  display: block;
  position: relative;

  svg {
    display: block;
  }
`;

const CustomIcon = styled.div`
  display: block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  transform: scale(1.2) translate(-15%, 0);

  svg {
    display: block;
  }
`;

const ArrowRight: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M7.25 4.5L11.75 9.00049L7.25 13.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
};

function ButtonNoRef(
  props: {
    children?: React.ReactNode;
    outline?: boolean | "initial";
    disabled?: boolean;
    theme?: "sunrise" | "charcoal" | "transparent" | "blur-charcoal" | "rxdiet";
    size?: "small" | "medium" | "large";
    active?: boolean;
    center?: boolean;
    hideArrow?: boolean;
    icon?: React.ReactNode;
    ref?: React.Ref<HTMLButtonElement | null>;
  } & ButtonProps,
  ref: React.Ref<HTMLButtonElement | null>
) {
  const elRef = React.useRef<HTMLButtonElement>(null);
  const {
    theme = "sunrise",
    outline = false,
    size = "large",
    active = false,
    icon,
    hideArrow,
    children,
    center,
    ...buttonProps
  } = props;
  const isAction = Boolean(icon);
  const compHideArrow = Boolean(hideArrow) || isAction;

  React.useImperativeHandle(ref, () => elRef.current, [elRef]);

  const handlePress = (e: PressEvent) => {
    const isSubmit = buttonProps.type === "submit";
    const form = elRef.current?.form;
    if (isSubmit && form) {
      form.requestSubmit(elRef.current);
    }
    buttonProps.onPress?.(e);
  };

  const handleClick = (e: MouseEvent) => {
    // Prevent the default behavior of the button, rely on the onPress event instead
    e.preventDefault();
  };

  useEffect(() => {
    elRef.current?.addEventListener("click", handleClick);
    return () => {
      elRef.current?.removeEventListener("click", handleClick);
    };
  }, [elRef.current]);

  return (
    <ButtonStyled
      data-outline={outline}
      data-theme={theme}
      data-size={size}
      data-hide-arrow={compHideArrow}
      data-action={isAction}
      data-center={center}
      data-active={active}
      {...buttonProps}
      onPress={handlePress}
      ref={elRef}
    >
      <ButtonInner>
        {icon && <CustomIcon>{icon}</CustomIcon>}
        <ButtonChildren>{children}</ButtonChildren>
        {!hideArrow && (
          <ButtonIcon>
            <ArrowRight />
          </ButtonIcon>
        )}
      </ButtonInner>
    </ButtonStyled>
  );
}

const Button = React.forwardRef(ButtonNoRef) as typeof ButtonNoRef;

export default Button;
