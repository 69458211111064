import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { fileState, toast } from "src/state/state";
import { PathnameToPopupMap } from "../AppQueryPopups/AppQueryPopupsRedirects";
import envVariables from "src/lib/envVariables";

interface Props {
  to?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  href?: string;
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  highlight?: boolean;
  target?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledLink = styled(RouterLink as any)`
  color: ${(p: { color?: string }): string => p.color ?? "#000"};
  text-decoration: underline;

  &[data-highlight="true"] {
    color: var(--color-sunrise-blue, #80aeff);
    text-decoration: underline;
  }
`;

const JustLink = styled.a`
  cursor: pointer;
`;

export const APP_LINK_URLS: string[] = [envVariables.WEB_URL].map(
  (url) => new URL(url).hostname
);

export const API_LINK_URLS: string[] = [
  envVariables.API_BASE_URL,
  envVariables.API_BASE_URL_LEGACY
].map((url) => new URL(url).hostname);

export function rewriteAppLinkUrls(link?: string): string | undefined {
  if (!link) return undefined;

  const url = new URL(link, window.location.href);

  const isAppLink = APP_LINK_URLS.some((appLinkUrl) => {
    return url.hostname === appLinkUrl;
  });

  if (isAppLink) {
    return url.pathname + url.search + url.hash;
  }

  return link;
}

const POPUP_QUERY_PARAM = "popup";

const Link: FC<Props> = (props) => {
  const target = useMemo(() => {
    const toHref = props.to ?? props.href ?? "";

    // return only the search params if link is an old popup url
    const popupTarget = PathnameToPopupMap[toHref];
    if (popupTarget) {
      return `?${POPUP_QUERY_PARAM}=${popupTarget}`;
    }

    // return only the search params if link is for popup
    if (toHref.includes(`?${POPUP_QUERY_PARAM}=`)) {
      // eslint-disable-next-line @typescript-eslint/prefer-destructuring
      const newTarget = toHref.split(`?${POPUP_QUERY_PARAM}=`)[1];
      return `?${POPUP_QUERY_PARAM}=${newTarget}`;
    }

    return toHref;
  }, [props.to, props.href]);

  const downloadFile = (url: URL) => {
    const pathParts = url.pathname.split("/");
    const id = pathParts[pathParts.length - 1];
    toast.show("download.started");
    void fileState.loadFile({ id: id, save: true });
  };

  if (props.href) {
    const url = new URL(target, window.location.href);
    const isApiLink = API_LINK_URLS.some((appLinkUrl) => {
      return url.hostname === appLinkUrl;
    });

    const isDownloadFile =
      isApiLink && target.includes("/files") && !target.includes("/token");

    return (
      <JustLink
        data-highlight={props.highlight}
        onClick={isDownloadFile ? () => downloadFile(url) : undefined}
        {...props}
        href={isDownloadFile ? undefined : target}
      >
        {props.children}
      </JustLink>
    );
  }

  const to = useMemo(() => {
    if (target.startsWith(`?${POPUP_QUERY_PARAM}=`)) {
      return target;
    }

    return rewriteAppLinkUrls(target);
  }, [target]);

  return (
    <StyledLink
      data-highlight={props.highlight}
      {...props}
      to={to ?? "#"}
      onClick={props.onClick}
    >
      {props.children}
    </StyledLink>
  );
};

export default Link;
