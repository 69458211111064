import React from "react";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";

const DebugPartnerSession = () => {
  const [{ partnerSession }, { setPartnerSession }] = useBloc(AppViewCubit);

  return (
    <div>
      <label>
        <input
          type="checkbox"
          checked={!!partnerSession}
          onChange={() => {
            setPartnerSession(!partnerSession);
          }}
        />
        <span style={{ marginLeft: "4px", fontSize: "1rem" }}>
          Is Partner Session
        </span>
      </label>
    </div>
  );
};

export default DebugPartnerSession;
