import type {
  ApiError,
  ChangeEmailRequest,
  ChangeEmailResponse,
  ChangePasswordRequest,
  PopulateUserRequest,
  ResetPasswordConfirmRequest,
  ResetPasswordResponse
} from "@9amhealth/openapi";
import {
  LillyControllerService,
  LoginRequest,
  Saml2AuthControllerService,
  UserControllerService
} from "@9amhealth/openapi";
import { App, AppState } from "@capacitor/app";
import { Cubit } from "blac";
import { processRequestQueue } from "src/api/ApiController";
import { globalEvents } from "src/constants/globalEvents";
import { addSentryBreadcrumb } from "src/lib/addSentryBreadcrumb";
import { FeatureFlagName, featureFlags } from "src/lib/featureFlags";
import parseJwt from "src/lib/parseJwt";
import reportErrorSentry from "src/lib/reportErrorSentry";
import BiometricCredentialsProvider from "src/state/UserCubit/CredentialProviders/BiometricCredentialsProvider";
import LocalStorageTokenCredentialsProvider from "src/state/UserCubit/CredentialProviders/LocalStorageTokenCredentialsProvider";
import type CredentialsProvider from "src/state/UserCubit/CredentialsProvider";
import type { ProviderCredentials } from "src/state/UserCubit/CredentialsProvider";
import BlockingLoadingOverlayController from "src/ui/components/BlockingLoadingOverlay/BlockingLoadingOverlayController";
import { LoadingKey } from "../LoadingCubit/LoadingCubit";
import { StorageController } from "../StorageBloc/StorageBloc";
import { UserPreferenceKeys } from "../UserPreferencesCubit/UserPreferencesCubit";
import {
  apiMiddleware,
  appViewState,
  healthSyncState,
  loadingState,
  pushNotificationState,
  subscriptionState,
  tracker,
  userPreferences,
  userState,
  websocketState
} from "../state";

type AuthenticationBlocState = {
  authenticationStatus: "authenticated" | "pending" | "unauthenticated";
  credentials: ProviderCredentials;
};

const biometricsProvider = new BiometricCredentialsProvider();

export default class AuthenticationBloc extends Cubit<AuthenticationBlocState> {
  isTempUser = false;
  availableProviders = new Set<CredentialsProvider>([biometricsProvider]);

  biometricsProvider = biometricsProvider;

  tokenProvider = new LocalStorageTokenCredentialsProvider();
  primaryProvider?: CredentialsProvider;

  constructor() {
    super({
      authenticationStatus: "unauthenticated",
      credentials: {}
    });
    void this.getCredentials();
    this.listenAppStatus();

    window.addEventListener(globalEvents.USER_CLEAR, () => {
      this.log("USER_CLEAR");
      this.emit({
        authenticationStatus: "unauthenticated",
        credentials: {}
      });
    });
  }

  get accessToken(): string | undefined {
    return this.state.credentials.accessToken;
  }

  get refreshToken(): string | undefined {
    return this.state.credentials.refreshToken;
  }

  extractUserId = (): string | undefined => {
    const parsedAccessToken = parseJwt(this.accessToken);
    return parsedAccessToken.sub;
  };

  private readonly log = (message: string, etc?: unknown): void => {
    if (featureFlags.getFlag(FeatureFlagName.loggingAuthenticationManager)) {
      // eslint-disable-next-line no-console
      console.warn(`[CRED]:${message}`, etc ?? "");
    }
    addSentryBreadcrumb("auth", message, "info", etc ?? "");
  };

  listenAppStatus = () => {
    void App.addListener("appStateChange", this.handleAppStateChange);
  };

  handleAppStateChange = (state: AppState) => {
    const { isActive } = state;
    if (isActive) {
      void this.getCredentials();
    }
  };

  async selectProvider(): Promise<void> {
    this.log("selectProvider", {
      availableProviders: this.availableProviders,
      primaryProvider: this.primaryProvider
    });

    if (this.primaryProvider) {
      return;
    }

    // select first available provider as primary
    for (const provider of this.availableProviders) {
      const available = await provider.isAvailable();
      if (available) {
        this.log("selectProvider: setting primaryProvider", provider);
        this.primaryProvider = provider;
        return;
      } else {
        this.log("selectProvider: provider not available", provider);
      }
    }
  }

  async getCredentials(): Promise<void> {
    this.log("getCredentials started");
    await this.selectProvider();
    let tokenCredentials: ProviderCredentials | undefined;
    let loginCredentials: ProviderCredentials | undefined;
    const primaryEnabled = await this.primaryProvider?.isEnabled();

    // 0. check primary
    if (primaryEnabled) {
      try {
        loginCredentials = await this.primaryProvider?.getCredentials();
      } catch (error) {
        reportErrorSentry(error);
      }
    }

    this.log("primaryProvider", {
      p: this.primaryProvider,
      primaryEnabled,
      loginCredentials
    });

    // if user has failed primary verification, clear credentials, this will show the login form
    if (
      primaryEnabled &&
      (!this.primaryProvider?.isVerified() ||
        !loginCredentials ||
        !loginCredentials.password ||
        !loginCredentials.username)
    ) {
      return;
    }

    // 1. check for tokens saved
    try {
      tokenCredentials = await this.tokenProvider.getCredentials();
    } catch (error) {
      reportErrorSentry(error);
    }

    const validRefreshToken = Boolean(tokenCredentials?.refreshToken);
    const validAccessToken = Boolean(tokenCredentials?.accessToken);

    const refreshTokenChanged =
      validRefreshToken && tokenCredentials?.refreshToken !== this.refreshToken;
    const accessTokenChanged =
      validAccessToken && tokenCredentials?.accessToken !== this.accessToken;

    // set token credentials if they are valid
    if (tokenCredentials && validRefreshToken) {
      this.log(
        "getCredentials: valid tokens. returning token credentials and setting token"
      );
      if (refreshTokenChanged || accessTokenChanged) {
        await this.setCredentials(tokenCredentials);
      } else {
        this.log(
          "refresh and access token are the same, not setting credentials"
        );
      }
    }

    // check if we have a refresh token
    if (tokenCredentials && !validAccessToken && validRefreshToken) {
      await this.refreshAccessToken();
    }

    // if there are no tokens, but we have login credentials, try to login
    if (
      !validRefreshToken &&
      loginCredentials?.username &&
      loginCredentials.password
    ) {
      this.log(
        "getCredentials: no tokens, but login credentials. trying to login"
      );
      try {
        await this.login({
          email: loginCredentials.username,
          password: loginCredentials.password
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    return;
  }

  private refreshAccessTokenRequest?: Promise<void>;
  refreshAccessToken = async (): Promise<void> => {
    if (this.refreshAccessTokenRequest) {
      this.log("refreshAccessToken: already running");
      return this.refreshAccessTokenRequest;
    }

    const request = new Promise<void>((resolve, reject) => {
      this.log("refreshAccessToken");
      if (!this.refreshToken) {
        this.log("refreshAccessToken: no refresh token");
        reject(new Error("No refresh token"));
      }

      UserControllerService.refreshToken()
        .then((response) => {
          this.setCredentials({
            accessToken: response.data.accessToken,
            refreshToken: this.refreshToken
          })
            .then(resolve)
            .catch((e: unknown) => reject(e as Error));
        })
        .catch(async (e: unknown) => {
          this.log("refreshAccessToken: failed", e);
          await this.clearAllUserData();
          await this.deleteCredentials();
        })
        .finally(() => {
          this.refreshAccessTokenRequest = undefined;
        });
    });

    this.refreshAccessTokenRequest = request;
    return request;
  };

  private refreshTimeout?: number;
  scheduleAutoRefresh = (): void => {
    const parsedAccessToken = parseJwt(this.accessToken);
    const timeTillAuthTokenExpires = parsedAccessToken.expiresInMs;
    const prematureRefreshTime = 60 * 1000 * 1; // 1 minute
    const max32BitInt = 2147483647;
    const maxTimeout = max32BitInt - 1;
    const ttl = Math.max(timeTillAuthTokenExpires - prematureRefreshTime, 0);
    // cap timeout to 32 bit int, because setTimeout can't handle more
    const cap = Math.min(ttl, maxTimeout);

    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
    }

    this.refreshTimeout = window.setTimeout(() => {
      void this.refreshAccessToken();
    }, cap);

    this.log("scheduleAutoRefresh", {
      prematureRefreshTime,
      cap,
      refreshesAt: new Date(Date.now() + cap),
      expiresAt: new Date(Date.now() + timeTillAuthTokenExpires),
      timeTillAuthTokenExpires
    });
  };

  async setCredentials(newCredentials: ProviderCredentials): Promise<void> {
    const currentAccessToken = this.accessToken;
    await this.selectProvider();
    const credentials = {
      ...this.state.credentials,
      ...newCredentials
    };

    let authenticationStatus: typeof this.state.authenticationStatus =
      "unauthenticated";

    if (credentials.accessToken) {
      authenticationStatus = "authenticated";
    } else if (credentials.refreshToken) {
      authenticationStatus = "pending";
    }

    this.emit({
      ...this.state,
      credentials,
      authenticationStatus
    });

    this.log("setCredentials", {
      authenticationStatus
    });

    const setCredentialsPromise =
      this.tokenProvider.setCredentials(credentials);

    try {
      await Promise.all([
        this.primaryProvider?.setCredentials(credentials),
        setCredentialsPromise
      ]);
      if (credentials.accessToken) {
        await processRequestQueue();
      }
    } catch (error) {
      reportErrorSentry(error);
    }

    this.log("auth state", authenticationStatus);
    const { id: currentlyLoadedUserDataId } = userState.state.userData ?? {};
    const userIdChanged =
      currentlyLoadedUserDataId !== StorageController.activeUserId;
    const credentialsContainEmailPassword = Boolean(
      credentials.username && credentials.password
    );
    // update ecternal state
    switch (authenticationStatus) {
      case "authenticated":
        void websocketState.connect();
        if (
          !currentlyLoadedUserDataId ||
          userIdChanged ||
          credentialsContainEmailPassword ||
          !currentAccessToken
        ) {
          await userState.loadRequiredAppUserData();
        }
        break;
      case "pending":
        websocketState.pauseSendingUntilAuthSuccess();
        break;
      case "unauthenticated":
        void websocketState.disconnect();
        break;
    }

    // schedule auto refresh
    if (credentials.refreshToken) {
      this.scheduleAutoRefresh();
    }
  }

  async deleteCredentials(): Promise<void> {
    await this.selectProvider();
    await this.setCredentials({
      accessToken: undefined,
      refreshToken: undefined,
      username: undefined,
      password: undefined
    });

    try {
      await Promise.all([
        this.primaryProvider?.deleteCredentials(),
        this.tokenProvider.deleteCredentials()
      ]);
    } catch (error) {
      reportErrorSentry(error);
    }
  }

  public readonly login = async (data: {
    email: string;
    password: string;
    mfa?: string;
  }): Promise<void> => {
    loadingState.start(LoadingKey.login);
    let error: ApiError | undefined;
    try {
      const response = await UserControllerService.login({
        ...data,
        role: LoginRequest.role.USER,
        sessionName: navigator.userAgent,
        mfaToken: data.mfa
      });

      await this.setCredentials({
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        username: data.email,
        password: data.password
      });

      addSentryBreadcrumb("auth", `Authenticated user`);
    } catch (e: unknown) {
      error = e as ApiError;
    } finally {
      loadingState.finish(LoadingKey.login);
    }

    if (error) {
      throw error;
    }
  };

  public readonly createAnonymousAccount = async (): Promise<void> => {
    if (this.isTempUser) return;
    if (this.refreshToken) return;

    if (this.state.authenticationStatus !== "unauthenticated") {
      reportErrorSentry(
        new Error("Creating anonymous account while already authenticated")
      );
    }

    try {
      const { data } = await UserControllerService.registerAnonymously();
      this.log("Created anonymous account");
      addSentryBreadcrumb("auth", `Successfully created anonymous account`);
      await this.setCredentials({
        accessToken: data.accessToken,
        refreshToken: data.refreshToken
      });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  public readonly register = async (
    data: {
      email: string;
      password: string;
    },
    funnel?: string
  ): Promise<void> => {
    let error: ApiError | undefined;
    let loginSuccess = false;

    loadingState.start(LoadingKey.register);

    // try to log in user first in case where the account already exists
    try {
      await this.login(data);
      loginSuccess = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info("Failed to login user");
    }

    if (!loginSuccess) {
      try {
        await UserControllerService.register({
          ...data,
          initiateEmailVerification: true
        });
        await userState.acceptConsents();
        await this.setCredentials({
          username: data.email,
          password: data.password,
          accessToken: this.accessToken,
          refreshToken: this.refreshToken
        });

        await userPreferences.updateUserPreferences({
          [UserPreferenceKeys.registerFunnel]: funnel
        });
        apiMiddleware.clearAll();
      } catch (e: unknown) {
        error = e as ApiError;
      }
    }

    loadingState.finish(LoadingKey.register);
    if (error) {
      reportErrorSentry(error);
      addSentryBreadcrumb("auth", `Failed to register user`);
      throw error;
    }
  };

  public readonly resetPassword = async (postData: {
    email: string;
  }): Promise<ResetPasswordResponse> => {
    let error: ApiError | undefined;
    loadingState.start(LoadingKey.requestPassReset);
    let response: ResetPasswordResponse | undefined;

    try {
      const { data } =
        await UserControllerService.initiatePasswordReset(postData);
      response = data;
      addSentryBreadcrumb("auth", `Successfully reset password`);
    } catch (e: unknown) {
      error = e as ApiError;
      addSentryBreadcrumb("auth", `Failed to reset password`);
    }

    loadingState.finish(LoadingKey.requestPassReset);

    if (error) {
      throw error;
    }
    if (!response) {
      throw new Error("No response");
    }

    return response;
  };

  public readonly changePassword = async (
    data: ChangePasswordRequest
  ): Promise<void> => {
    loadingState.start(LoadingKey.changePassword);
    let error: ApiError | undefined;

    try {
      await UserControllerService.changePassword(data);
      addSentryBreadcrumb("auth", `Successfully changed password`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = e as ApiError;
    }
    loadingState.finish(LoadingKey.changePassword);

    if (error) {
      throw error;
    }
  };

  public readonly changeEmail = async (
    changeEmailRequest: ChangeEmailRequest
  ): Promise<ChangeEmailResponse> => {
    apiMiddleware.clearAll();
    // loadingState.start(LoadingKey.verifyEmail);
    let response: ChangeEmailResponse | undefined;
    let error: ApiError | undefined;
    try {
      const { data } =
        await UserControllerService.changeEmail(changeEmailRequest);
      response = data;
      addSentryBreadcrumb("auth", `Successfully changed email`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = e as ApiError;
    }
    loadingState.finish(LoadingKey.verifyEmail);

    if (error) {
      throw error;
    }
    if (!response) {
      throw new Error("No response");
    }
    return response;
  };

  public readonly resetPasswordVerify = async (
    formData: ResetPasswordConfirmRequest
  ): Promise<void> => {
    loadingState.start(LoadingKey.updatePass);
    let error: ApiError | undefined;
    try {
      await UserControllerService.confirmPasswordReset(formData);
      addSentryBreadcrumb("auth", `Successfully changed password`);
    } catch (e: unknown) {
      reportErrorSentry(e);
      error = e as ApiError;
    }
    loadingState.finish(LoadingKey.updatePass);

    if (error) {
      throw error;
    }
  };

  public readonly logout = async (): Promise<void> => {
    const hasToken = Boolean(this.accessToken);
    BlockingLoadingOverlayController.startLoading({
      bg: "branded",
      timeout: -1
    });

    if (hasToken) {
      await healthSyncState.unregisterDevice();
      await pushNotificationState.unregister();
      await UserControllerService.logout();
      await Promise.all([
        this.primaryProvider?.deleteCredentials(),
        this.tokenProvider.deleteCredentials()
      ]);
      void websocketState.disconnect();
      void this.deleteCredentials();
      void this.clearAllUserData();
    }

    BlockingLoadingOverlayController.endLoading();
  };

  clearAllUserData = async (): Promise<void> => {
    tracker.resetMixpanelUser();
    window.dispatchEvent(new CustomEvent(globalEvents.USER_CLEAR));
    apiMiddleware.clearAll();
    userState.reset();
    userPreferences.reset();
    subscriptionState.reset();
  };

  reportFaultyAccessToken = async (): Promise<void> => {
    this.log("reportFaultyAccessToken");
    return this.refreshAccessToken();
  };

  sendHandoverToken = async (options: {
    token: string;
    redirect?: string;
    type: PopulateUserRequest.type;
  }): Promise<void> => {
    const { token, type, redirect } = options;
    try {
      await LillyControllerService.populateUser({ token, type });
    } catch (e) {
      reportErrorSentry(e);
    } finally {
      if (redirect) {
        window.location.replace(redirect);
      }
    }
  };

  authWithSamlToken = async (token: string): Promise<void> => {
    const decodedToken = parseJwt(token);
    const reg = decodedToken.samlreg ?? "";
    this.isTempUser = false;
    userState.isTempUser = false;
    appViewState.setPartnerSource(reg);

    const authData = await Saml2AuthControllerService.exchange({ token });
    await this.setCredentials({
      accessToken: authData.data.accessToken,
      refreshToken: authData.data.refreshToken
    });
  };
}
